import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './assets/fonts/fonts';
import './App.scss';
import { DashboardPage } from 'pages/Dashboard/Dashboard';
import ProtectedRoute from 'routes/ProtectedRoute';
import { CreateNTSReportPage } from 'pages/Reports/Create/NTS';
import { CreateHAReportPage } from 'pages/Reports/Create/HA';
import { NTS_HA_REPORT } from 'pages/NTS-HAReport/NTS-HAReport';
import { EditNTSReportPage } from 'pages/Reports/Edit/EditNTSReportComponent';
import { EditHAReportPage } from 'pages/Reports/Edit/EditHAReportComponent';
import ViewNTSReportPage from 'pages/Reports/View/ViewNTSReportComponent';
import ViewHAReportPage from 'pages/Reports/View/ViewHAReportComponent';
import ValidationNTSReportPage from 'pages/Reports/Validation/ValidationNTSReportComponent';
import ValidationHAReportPage from 'pages/Reports/Validation/ValidationHAReportComponent';
import { GlobalMapPage } from 'pages/GlobalMap/GlobalMapPage';
import { UsersPage } from 'pages/Users/Users';
import { DroneDataPage } from 'pages/DroneData/DroneDataPage';
import { SecretsSettingsPage } from 'pages/SecretsSettings/SecretsSettingsPage';
import { ExportPage } from 'pages/Export/ExportPage';
import { DonorMapPage } from 'pages/DonorMap/DonorMapPage';
import { ProfilePage } from 'pages/Profile/Profile';
import { MachineryTrackingPage } from 'pages/MachineryTracking/MachineryTrackingPage';
import { NotFoundPage } from 'pages/NotFound/NotFoundPage';
import { EOREPage } from 'pages/EORE/EOREPage';
import { LoginPage } from './pages/Login/Login';
import { Menu } from './layout/Menu';
import { CreateEOREReportPage } from 'pages/EORE/Reports/Create';
import { EditEOREReportPage } from 'pages/EORE/Reports/Edit';
import { ViewEOREReportPage } from 'pages/EORE/Reports/View';
import { ValidationEOREReportPage } from 'pages/EORE/Reports/Validation';

export const App = () => (
  <Router>
    <Routes>
      <Route exact path="/login" element={<LoginPage />} />
      <Route path="/donors/:token" element={<DonorMapPage />} />
      <Route exact path="*" element={<NotFoundPage />} />
      <Route element={(
        <main className="dashboard-main">
          <aside>
            <Menu />
          </aside>
          <ProtectedRoute />
        </main>
      )}
      >
        <Route path="/" element={<DashboardPage />} />
        <Route exact path="/dashboard/" element={<DashboardPage />} />
        <Route exact path="/profile" element={<ProfilePage />} />

        <Route
          exact
          path="/reports/create-report/nts"
          element={<CreateNTSReportPage />}
        />
        <Route
          exact
          path="/reports/eore"
          element={<EOREPage />}
        />
        <Route
          exact
          path="/reports/eore/create"
          element={<CreateEOREReportPage />}
        />
        <Route
          exact
          path="/reports/eore/edit/:reportId"
          element={<EditEOREReportPage />}
        />
        <Route
          exact
          path="/reports/eore/view/:reportId"
          element={<ViewEOREReportPage />}
        />
        <Route
          exact
          path="/reports/eore/validation/:reportId"
          element={<ValidationEOREReportPage />}
        />
        <Route exact path="/users" element={<UsersPage />} />
        <Route
          exact
          path="/reports/create-report/ha"
          element={<CreateHAReportPage />}
        />
        <Route exact path="/nts-ha-report/" element={<NTS_HA_REPORT />} />
        <Route
          path="/edit-nts-report/:reportId"
          element={<EditNTSReportPage />}
        />
        <Route
          path="/edit-ha-report/:reportId"
          element={<EditHAReportPage />}
        />
        <Route
          path="/view-nts-report/:reportId"
          element={<ViewNTSReportPage />}
        />
        <Route
          path="/view-ha-report/:reportId"
          element={<ViewHAReportPage />}
        />
        <Route
          path="/validation-nts-report/:reportId"
          element={<ValidationNTSReportPage />}
        />
        <Route
          path="/validation-ha-report/:reportId"
          element={<ValidationHAReportPage />}
        />
        <Route path="/global-map/" element={<GlobalMapPage />} />
        <Route path="/drone-data/" element={<DroneDataPage />} />
        <Route path="/secrets-settings/" element={<SecretsSettingsPage />} />
        <Route path="/export/" element={<ExportPage />} />
        {/* <Route path="/machinery-tracking/" element={<MachineryTrackingPage />} /> */}
      </Route>
    </Routes>
  </Router>
);
