import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import Cleave from 'cleave.js/react';

export const PercentAfterSession = (props) => {
  const { formData, handlePercent } = props;

  return (
    <AccordionBodyItem
      accordionSubId={(
        <div>
          <span>3.2</span>
        </div>
      )}
      titleUA="Опитування ПІСЛЯ сесії"
      titleEN="Survey AFTER the session"
      children={(
        <div className="flex-group">
          <Cleave
            placeholder="0"
            id="percentage_after_session"
            name="percentage_after_session"
            className="number"
            value={formData.info.final_indicators.percentage_after_session}
            autoComplete="off"
            options={{
              numericOnly: true,
              stripLeadingZeroes: true,
            }}
            onChange={(e) => handlePercent('percentage_after_session', e.target.value.replace(/^0+/, ''))}
            disable="false"
          />
          <span>%</span>
        </div>
      )}
    />
  );
};
