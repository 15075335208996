import moment from 'moment';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import { React, useState } from 'react';
import { ReactComponent as PeopleSvg } from 'assets/images/icons/People.svg';
import { ReactComponent as ShedulleSvg } from 'assets/images/icons/Shedulle.svg';
import { ReactComponent as MapSvg } from 'assets/images/icons/map1.svg';
import { ReactComponent as PhotoSvg } from 'assets/images/icons/Photo.svg';
import { DatePickerWrapper } from 'component/DataPicker';
import { ButtonComponent } from 'component/Button';
import { squareMetersToHectares } from 'utils/Helper';
import { CheckboxInput } from 'component/Checkbox';

export const MainDonorInfo = (props) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const handleHazardAreaCheckbox = (value, checked) => {
    if (checked) {
      props.setHazardAreaTypes((prev) => [...prev, value]);
    } else {
      props.setHazardAreaTypes((prev) => prev.filter((item) => item !== value));
    }
  };

  const handleHazardTypeCheckbox = (value, checked) => {
    if (checked) {
      props.setHazardTypes((prev) => [...prev, value]);
    } else {
      props.setHazardTypes((prev) => prev.filter((item) => item !== value));
    }
  };

  return (
    <div className="mapbox-popup donor-main-info">

      <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
        <div className="header">
          <span>{props.donorName || 'Unknown'}</span>
          <div className="flex-right">
            <TabList>
              <Tab>NTS</Tab>
              <Tab>HA</Tab>
            </TabList>
          </div>
        </div>
        <TabPanel className="body">
          <div className="flex-group">
            <PeopleSvg />
            <span>
              {props.mainInfo?.nts_reports?.teams_count || 0}
              {' '}
              teams
            </span>
          </div>
          <div className="flex-group">
            <ShedulleSvg />
            <span>
              {props.mainInfo?.nts_reports?.report_count || 0}
              {' '}
              reports
            </span>
          </div>
        </TabPanel>
        <TabPanel className="body">
          <div className="flex-group">
            <PeopleSvg />
            <span>
              {props.mainInfo?.ha_reports?.teams_count || 0}
              {' '}
              teams
            </span>
          </div>
          <div className="flex-group">
            <ShedulleSvg />
            <span>
              {props.mainInfo?.ha_reports?.report_count || 0}
              {' '}
              reports
            </span>
          </div>
          <div className="flex-group">
            <MapSvg />
            <span>{squareMetersToHectares(props.mainInfo?.ha_reports?.hectares) || 0}</span>
          </div>
          <div className="flex-group">
            <PhotoSvg />
            <span>
              Direct evidences:
              {props.mainInfo?.ha_reports?.direct_evidences_count || 0}
            </span>
          </div>
          <div className="flex-group">
            <PhotoSvg />
            <span>
              Inirect evidences:
              {props.mainInfo?.ha_reports?.indirect_evidences_count || 0}
            </span>
          </div>

        </TabPanel>
      </Tabs>

      <div className="footer">
        <div className="flex-group flex-between">
          <span>From</span>
          <DatePickerWrapper
            value={props.dateFrom ? moment(props.dateFrom, 'DD.MM.YYYY') : null}
            handleDatePicker={(value) => (value
              ? props.setDateFrom(moment(value, 'DD.MM.YYYY').format('L'))
              : props.setDateFrom(null))}
            disableDateAfter={props.dateTo ? props.dateTo : null}
          />

        </div>
        <div className="flex-group flex-between">
          <span>To</span>
          <DatePickerWrapper
            value={props.dateTo ? moment(props.dateTo, 'DD.MM.YYYY') : null}
            handleDatePicker={(value) => (value
              ? props.setDateTo(moment(value, 'DD.MM.YYYY').format('L'))
              : props.setDateTo(null))}
            disableDateBefore={props.dateFrom ? props.dateFrom : null}
          />
        </div>
        {activeTab === 1
          && (<>
            <div className="centered">
              <CheckboxInput
                id="I3T|SHA"
                value="I3T|SHA"
                label="SHA"
                name="I3T|SHA"
                checked={props.hazardAreaTypes.includes('I3T|SHA')}
                handleInput={handleHazardAreaCheckbox}
              />
              <CheckboxInput
                id="3T|CHA"
                value="3T|CHA"
                label="CHA"
                name="3T|CHA"
                checked={props.hazardAreaTypes.includes('3T|CHA')}
                handleInput={handleHazardAreaCheckbox}
              />
            </div>

            <div className="line-blue" />
            <div className="centered">
              <CheckboxInput
                id="minefield"
                value="minefield"
                label="Minefield"
                name="Minefield"
                checked={props.hazardTypes.includes('minefield')}
                handleInput={handleHazardTypeCheckbox}
              />

              <CheckboxInput
                id="battlefield"
                value="battlefield"
                label="Battlefield"
                name="Battlefield"
                checked={props.hazardTypes.includes('battlefield')}
                handleInput={handleHazardTypeCheckbox}
              />
            </div></>)}
        <div className="buttons-group">
          <ButtonComponent
            label="Update"
            handleButton={() => {
              const params = {
                hazard_area_type: props.hazardAreaTypes,
                hazard_type: props.hazardTypes,
                dateFrom: props.dateFrom,
                dateTo: props.dateTo,
              };
              props.updateGeojson(params);
            }}
          />
          <ButtonComponent
            className="danger"
            label="Reset"
            handleButton={() => {
              props.setDateTo(null);
              props.setDateFrom(null);
              props.setResetFilter(true);
              props.setHazardAreaTypes([]);
              props.setHazardTypes([]);
            }}
          />
        </div>
      </div>
    </div>

  );
};
