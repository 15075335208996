import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonComponent } from './Button';

export const MapModal = (props) => {
  const navigate = useNavigate();

  const PropertyBlocks = () => Object.keys(props.data).map((key) => {
    if (key !== 'color') {
      return (
        <div className="flex-group flex-between" key={key}>
          <div className="double-text">
            <span>{key}</span>
          </div>
          <span>{props.data[key] || 'Unknown'}</span>
        </div>
      );
    }
    return false;
  });

  if (props.visible && props.contentSize === 'standart') {
    return (
      <div className={`mapbox-popup ${props.contentSize}`}>
        <div className="flex-column-group">
          <div className="flex-group flex-between">
            {props.data.hazard_name && (
              <>
                <div className="double-text">
                  <span>Назва звіту:</span>
                  <span>Report name:</span>
                </div>
                <span>{props.data.hazard_name || 'Unknown'}</span>
              </>
            )}
          </div>
          {props.data.internal_id && (
            <>
                <div className="double-text">
                <span>Внутрішній id:</span>
                <span>Internal Id:</span>
                </div>
                <span>{props.data.internal_id || 'Unknown'}</span>
              </>
          )}
          <div className="flex-group flex-between">
            <div className="double-text">
              <span>Дата звіту:</span>
              <span>Date of Report:</span>
            </div>
            <span>{props.data.report_date || 'Unknown'}</span>
          </div>
          <div className="flex-group flex-between">
            <div className="double-text">
              <span>Команда:</span>
              <span>Team:</span>
            </div>
            <span>{props.data.team_id || 'Unknown'}</span>
          </div>
          {props.data.hazard_type && (
            <ButtonComponent
              handleButton={() => navigate(`/view-ha-report/${props.data.id}`, {
                state: { selectedHaReportId: props.data.id },
              })}
              label="Go to report"
            />
          )}
        </div>
      </div>
    );
  } if (props.visible && props.contentSize === 'full') {
    return (
      <div className={`mapbox-popup ${props.contentSize}`}>
        <div className="flex-column-group">
          <PropertyBlocks />
        </div>
      </div>
    );
  }
  return null;
};
