import React from 'react';
import { AccordionBodyItem } from 'component/Accordion';
import { InputComponent } from 'component/Input';

export const Handouts = (props) => {
  const {
    formData,
    setFormData,
    type,
    handouts,
  } = props;

  const totalHandouts = handouts?.reduce((total, handout) => {
    const currentHandout = formData.info?.session_information[type]?.handouts?.find(
      (item) => item.handout_id === handout.handout_id && item.count !== 0
    );
    return total + (currentHandout?.count || 0);
  }, 0);

  const handleHandout = (id, value) => {
    if (/[^0-9]/.test(value)) return;

    const updatedHandouts = formData.info.session_information[type].handouts?.map((handout) => {
      if (handout.handout_id === id) {
        return {
          ...handout,
          count: value === '' ? 0 : Number(value),
        };
      }
      return handout;
    });

    setFormData((prevFormData) => ({
      ...prevFormData,
      info: {
        ...prevFormData.info,
        session_information: {
          ...prevFormData.info.session_information,
          [type]: {
            ...prevFormData.info.session_information[type],
            handouts: updatedHandouts,
          },
        },
      },
    }));
  };

  return (
    <>
      <AccordionBodyItem
        titleUA="Матеріали для розповсюдження"
        titleEN="Materials for distribution"
      />
      <div className="flex-group handouts flex-wrap">
        {!handouts?.length && (
          <span className="text-center">
            Немає матеріалів для розповсюдження
          </span>
        )}
        {handouts?.length !== 0 && handouts?.map((handout) => (
          <Handout
            key={handout.handout_id}
            handout={handout}
            formData={formData}
            type={type}
            handleHandout={handleHandout}
          />
        ))}
      </div>
      {handouts?.length !== 0 && <><div className='total-beneficiaries-container' style={{ justifyContent: 'end' }}>
        <div className='total-beneficiaries'>
          <div>
            Total:
          </div>
          <div>
            {totalHandouts}
          </div>
        </div>
      </div><div className='line'></div></>
      }
    </>
  );
};

export const Handout = (props) => {
  const {
    handout,
    handleHandout,
    formData,
    type,
  } = props;
  if (!handout.name) return null;

  return (
    <div className="handout-item">
      <span className="text">{handout.name}</span>
      <InputComponent
        type="text"
        placeholder="0"
        id={`${handout.handout_id}-count`}
        name={`${handout.handout_id}-count`}
        className="number"
        value={
          formData.info.session_information[type].handouts?.find(
            (item) => item.handout_id === handout.handout_id
          )?.count ?? ''
        }
        autoComplete="off"
        handleInput={(value) => handleHandout(handout.handout_id, value)}
      />
    </div>
  );
};
