import React, { useEffect, useRef, useState } from 'react';
import { AccordionBodyItemView } from 'component/Accordion';
import EOREPoint from 'assets/images/icons/location.png';
import { featureCollection, point } from '@turf/helpers';
import { Map } from 'component/Map';
import { convertCoords4326To3857 } from 'utils/Helper';
import { setBufferZone } from 'pages/GlobalMap/Layers';
import { setLayerVisibility } from 'pages/GlobalMap/MapLayers';

export const RegionView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId="2.1"
      titleUA="Область"
      titleEN="Region"
      children={formData.info?.session_information?.region}
    />
  );
};

export const DistrictView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId="2.2"
      titleUA="Район"
      titleEN="District"
      children={formData.info?.session_information?.district}
    />
  );
};

export const LocalCommunityView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId="2.3"
      titleUA="Територіальна громада"
      titleEN="Local community"
      children={formData.info?.session_information?.local_community}
    />
  );
};

export const LocalityView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId="2.4"
      titleUA="Населений пункт"
      titleEN="Locality"
      children={formData.info?.session_information?.settlement_name}
    />
  );
};

export const SettlementCodeView = (props) => {
  const { formData } = props;

  return (
    <AccordionBodyItemView
      accordionSubId="2.5"
      titleUA="Код населеного пункту"
      titleEN="Settlement code"
      children={formData.info?.session_information?.settlement_code}
    />
  );
};

export const LocationMapView = (props) => {
  const { formData, zone } = props;
  const [map, setMap] = useState(null);
  const [coords3857, setCoords3857] = useState([null, null]);

  const abortController = useRef(new AbortController());

  useEffect(() => {
    const loadBufferZone = async () => {
      if (!map) return;
      if (map.getSource('buffer-zone')) return;
      try {
        await setBufferZone(map, abortController.current.signal);
        setLayerVisibility(map, 'buffer-zone-layer', true);
      } catch (error) {
        console.error('Error loading buffer zone:', error);
      }
    };
  
    loadBufferZone();
  }, [map]);

  const addMapboxPointLayer = (geojson) => {
    if (!geojson) return;
    if (!map.getSource('eore-point')) {
      map.addSource('eore-point', {
        type: 'geojson',
        data: geojson,
        generateId: true,
      });

      [{ 'eore-point-icon': EOREPoint }].forEach((icon) => {
        map.loadImage(Object.values(icon)[0], (error, image) => {
          if (error) throw error;
          map.addImage(Object.keys(icon)[0], image);
        });
      });

      map.addLayer({
        id: 'eore-point-layer',
        type: 'symbol',
        source: 'eore-point',
        layout: {
          'icon-image': 'eore-point-icon',
          'icon-size': 1,
          'icon-offset': [0, -12],
          'icon-allow-overlap': true,
        },
      });
    } else {
      map.getSource('eore-point').setData(geojson);
    }
  };

  function hasTwoDigits(arr) {
    return arr?.some((num) => (num >= 10 && num <= 99) || (num <= -10 && num >= -99));
  }

  useEffect(() => {
    if (!map) return;
    const prop = formData.info.point?.coordinates;

    if (prop && prop[0] && prop[1]) {
      addMapboxPointLayer(featureCollection([point(prop)]));
      if (hasTwoDigits(formData.point?.coordinates)) {
        setCoords3857(convertCoords4326To3857(formData.point?.coordinates, zone));
      } else {
        setCoords3857(formData.point?.coordinates);
      }
    } else {
      addMapboxPointLayer(featureCollection([]));
      setCoords3857([null, null]);
    }
  }, [map, formData]);

  return (
    <div className="flex-column-group no-y-margin w-100">
      <Map
        map={map}
        setMap={setMap}
      />
      <div className="flex-group">
        <div className="double-text">
          <span>Координати</span>
          <span>Coordinates</span>
        </div>
        <div className="line" />
        <div className="flex-group text flex-wrap">
          <span>
            Long:
            {coords3857[0] ? coords3857[0]?.toFixed(0) : 0}
          </span>
          <span>
            Lat:
            {coords3857[1] ? coords3857[1]?.toFixed(0) : 0}
          </span>
        </div>
      </div>
    </div>
  );
};
