import React, { useState } from 'react';
import { ReactComponent as UpdateIcon } from 'assets/images/icons/update2.svg';
import { ReactComponent as FilterSvg } from 'assets/images/icons/filter.svg';
import { ReactComponent as CancelSvg } from 'assets/images/icons/close_24px.svg';
import { DatePickerWrapper } from 'component/DataPicker';
import moment from 'moment';
import { ButtonComponent } from 'component/Button';
import { Dialog } from '@mui/material';

export const EoreDatapickers = (props) => {
  const {
    dateTo,
    setDateTo,
    dateFrom,
    setDateFrom,
    setTeamLeadId,
  } = props;

  return (
    <>
      <div
        className="flex-group flex-between"
        style={{ width: '150px', paddingRight: '10px' }}
      >
        <DatePickerWrapper
          handleDatePicker={(value) => setDateFrom(moment(value, 'DD.MM.YYYY').format('L'))}
          value={dateFrom ? moment(dateFrom, 'DD.MM.YYYY') : null}
        />
      </div>
      <div
        className="flex-group flex-between"
        style={{ width: '150px', paddingRight: '10px' }}
      >
        <DatePickerWrapper
          handleDatePicker={(value) => setDateTo(moment(value, 'DD.MM.YYYY').format('L'))}
          value={dateTo ? moment(dateTo, 'DD.MM.YYYY') : null}
        />
      </div>
      <div className="text-hover p-1">
        <UpdateIcon onClick={() => {
          setDateFrom(null);
          setDateTo(null);
          setTeamLeadId(null);
        }}
        />
      </div>
    </>
  );
};

export const EoreFilter = () => {
  const [open, setOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resetFilteredData = () => {
    setSelectedFilters([]);
  };

  const applyFilters = () => {
    setOpen(false);
  };

  return (
    <div className="text-center">
      <ButtonComponent
        className="filter-btn"
        configuration="filled"
        state="enabled"
        iconRight={<FilterSvg />}
        label="Filter"
        handleButton={handleClickOpen}
      />
      <Dialog
        className="filter-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="confirm-popup">
          <div className="header">
            <span className="title">Filter</span>
            <CancelSvg className="closeBtn" onClick={handleClose} />
          </div>
          <div className="select-wrapper w-100" style={{ gap: '0' }}>
            <span className="label">Select filters</span>
          </div>

          <div className="buttons-group">
            <ButtonComponent
              iconLeft={<CancelSvg />}
              className="danger"
              handleButton={resetFilteredData}
              label="Reset"
            />
            <ButtonComponent
              iconLeft={<FilterSvg />}
              handleButton={() => {
                applyFilters();
              }}
              label="Apply"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
