import axios from 'axios';
import { React, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { Link, useNavigate } from 'react-router-dom';
import { Loader } from 'component/Loader';
import './index.scss';
import ViewIcon from 'assets/images/icons/Eye-on-green.svg';
import { ReactComponent as AddSvg } from 'assets/images/icons/add_24px.svg';
import { CreateReportDialog } from 'component/Dialog';
import { URLS } from 'routes/Urls';
import { ButtonComponent } from 'component/Button';
import { DatePickerWrapper } from 'component/DataPicker';
import { axiosWrapper } from 'utils/Helper';
import DashboardCard from './DashboardCard';
import { useUser } from 'routes/UserContext';

export const DashboardPage = () => {
  const [loader, setLoader] = useState(false);
  const [ntsData, setNtsData] = useState([]);
  const [haData, setHaData] = useState([]);
  const [eoreData, setEoreData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);

  const { user } = useUser()

  const navigate = useNavigate();

  const handleTabSelect = (index) => {
    setActiveTab(index);
  };

  const confirmPopup = () => {
    const reportName = localStorage.getItem('reportName' || '');
    if (reportName) {
      navigate(URLS.CREATE_NTS_REPORT_PAGE, {
        state: { name: reportName },
      });
    }
  };

  const fetchData = (url, startDateProp, endDateProp) => {
    axiosWrapper(() => {
      setLoader(true);
      axios
        .get(url, {
          params: {
            date_from: startDateProp?.format('DD.MM.YYYY') || null,
            date_to: endDateProp?.format('DD.MM.YYYY') || null,
          },
        })
        .then((response) => {
          if (url.includes('nts')) {
            setNtsData(response.data);
          } else if (url.includes('ha')) {
            setHaData(response.data);
          } else if (url.includes('eore')) {
            setEoreData(response.data);
          }
        })
        .catch((error) => {
          console.error('Error', error);
        })
        .finally(() => {
          setLoader(false);
        });
    });
  };

  useEffect(() => {
    document.title = 'Dashboard';
    if (!initialLoad) {
      if (activeTab === 0) {
        fetchData('/reports/dashboard/nts', startDate, endDate);
      }
      if (activeTab === 1) {
        fetchData('/reports/dashboard/ha', startDate, endDate);
      }
      if (activeTab === 2) {
        fetchData('/reports/dashboard/eore', startDate, endDate);
      }
    } else {
      fetchData('/reports/dashboard/nts', null, null);
      setInitialLoad(false);
    }
  }, [startDate, endDate, activeTab, initialLoad]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <section>
      <div className="users-header">
        <div className="users-title">Dashboard</div>
        <div className="date-with-button">
          <div style={{ paddingRight: '20px' }} className="dateFromTo">
            <div
              className="flex-group flex-between"
              style={{ width: '220px' }}
            >
              <span>From</span>
              <DatePickerWrapper
                value={startDate}
                handleDatePicker={handleStartDateChange}
              />
            </div>
            <div
              className="flex-group flex-between"
              style={{ width: '220px' }}
            >
              <span>To</span>
              <DatePickerWrapper
                value={endDate}
                handleDatePicker={handleEndDateChange}
              />
            </div>
          </div>
          <Tabs selectedIndex={activeTab} onSelect={handleTabSelect}>
            <TabList>
              <Tab>NTS Reports</Tab>
              <Tab>HA Reports</Tab>
              <Tab>EORE Reports</Tab>
            </TabList>
          </Tabs>
        </div>
      </div>
      <Tabs selectedIndex={activeTab} onSelect={handleTabSelect}>
        <TabPanel>
          <div className="allCards">
            <DashboardCard
              reports={ntsData}
              reportType="nts"
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="allCards">
            <DashboardCard
              reports={haData}
              reportType="ha"
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="allCards">
            <DashboardCard
              reports={eoreData}
              reportType="eore"
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </TabPanel>
      </Tabs>

      <div className="dashboard-footer">
        <button
          className="viewAllReports-button"
          onClick={() => {
            navigate(activeTab !== 2 ? URLS.NTS_NA_REPORT : URLS.EORE_LIST);
          }}
        >
          <img className="view-icon" src={ViewIcon} alt="view all reports" />
          View all reports
        </button>
        {user?.permissions?.add_nto_report && activeTab !== 2 && <CreateReportDialog confirmPopup={confirmPopup} />}
        {activeTab === 2 && (
          <Link to="/reports/eore/create">
            <ButtonComponent
              iconLeft={<AddSvg />}
              className="primary"
              handleButton={() => { }}
              label="Create report"
            />
          </Link>
        )}
      </div>

      <Loader visible={loader} />
      <ToastContainer />
    </section>
  );
};
