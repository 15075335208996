import 'assets/styles/nav.scss';
import { React, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { LogoFSD } from 'component/Logo';
import { ReactComponent as DashboardSvg } from 'assets/images/icons/Dashboard.svg';
import { ReactComponent as PeopleIcon } from 'assets/images/icons/People.svg';
import logoutIcon from 'assets/images/icons/Logout.svg';
import { useAuth } from 'routes/AuthContext';
import { URLS } from 'routes/Urls';

import '../assets/styles/menu.scss';
import { ReactComponent as ShedulleIcon } from 'assets/images/icons/Shedulle.svg';
import { ReactComponent as MapIcon } from 'assets/images/icons/map1.svg';
import { ReactComponent as UserIcon } from 'assets/images/icons/User.svg';
import { ReactComponent as EoreIcon } from 'assets/images/icons/eore.svg';
import { ReactComponent as DroneIcon } from 'assets/images/icons/drone.svg';
import { ReactComponent as ExportIcon } from 'assets/images/icons/file_download.svg';
import { ReactComponent as NetworkIcon } from 'assets/images/icons/Network.svg';
import { CreateReportDialog } from 'component/Dialog';

import { ReactComponent as MoreIcon } from 'assets/images/icons/more.svg';
import { useUser } from 'routes/UserContext';
import { Notifications } from '../pages/Profile/Notifications/Notifications';

const UserInfo = () => {
  const navigate = useNavigate();
  const { user, avatar, isAvatarLoading } = useUser();

  const handleUserInfoClick = () => {
    if (user?.permissions?.change_user_self) {
      navigate('/profile');
    }
  };

  return (
    <div className="user-info">
      {user && (
        <div className="flex-group flex-between">
          <div className="avatar" onClick={handleUserInfoClick}>
            {!avatar && !isAvatarLoading
              && (
                <div className="user-icon">
                  <UserIcon />
                </div>
              )}
            {isAvatarLoading
              && (
                <Oval
                  height={50}
                  width={50}
                  color="#00B1B7"
                  secondaryColor="#00B1B7"
                />
              )}
            {!isAvatarLoading && avatar
              && <img src={avatar} alt="User Avatar" />}
          </div>
          <div>
            <div className="name" onClick={handleUserInfoClick}>
              {user.name}
              {' '}
              {user.surname}
            </div>
            <div className="role" onClick={handleUserInfoClick}>
              {user.role?.name}
            </div>
          </div>
          <Notifications />
          <UserMoreInfo />
        </div>
      )}
    </div>
  );
};

const UserMoreInfo = () => {
  const [openNav, setOpenNav] = useState(false);
  const { user } = useUser();
  const navigate = useNavigate();

  const handleLinkClick = (url) => {
    setOpenNav(false);
    navigate(url);
  };
  return (
    user?.permissions?.view_settings && (
      <div>
        <div onClick={() => setOpenNav(!openNav)}>
          <MoreIcon className="text-hover" />
        </div>
        {openNav && (
          <div className="user-nav">
            <ul onClick={() => handleLinkClick(URLS.SECRETS_SETTINGS)}>
              <li>
                <span>Settings</span>
              </li>
            </ul>
          </div>
        )}
      </div>
    )
  );
};

export const Menu = (props) => {
  const [activeItem, setActiveItem] = useState(null);

  const location = useLocation();
  const { user } = useUser();

  const { pathname } = location;

  useEffect(() => {
    setActiveItem(pathname);
  }, [pathname]);

  const navigate = useNavigate();

  const confirmPopup = () => {
    const reportName = localStorage.getItem('reportName' || '');
    if (reportName) {
      navigate(URLS.CREATE_NTS_REPORT_PAGE, {
        state: { name: reportName },
      });
    }
  };

  return (
    <nav>
      <LogoFSD type="white" text="on" />
      <ul>
        <Link to={URLS.DASHBOARD}>
          <li className={`menu-title ${activeItem === '/dashboard/'
            ? 'active'
            : ''}`}
          >
            <DashboardSvg />
            Dashboard
          </li>
        </Link>

        {user?.permissions?.view_user && (
          <Link to={URLS.USERS}>
            <li className={`menu-title ${activeItem === '/users/'
              ? 'active'
              : ''}`}
            >
              <PeopleIcon />
              Users
            </li>
          </Link>
        )}

        {user?.permissions?.view_nto_report && (
          <Link to={URLS.NTS_NA_REPORT}>
            <li className={`menu-title ${activeItem === '/nts-ha-report/'
              ? 'active'
              : ''}`}
            >
              <ShedulleIcon />
              NTS/HA Reports
            </li>
          </Link>
        )}
        {(user?.permissions?.view_eore_report
          || user?.permissions?.eore_templates)
          && (
            <Link to={URLS.EORE_LIST}>
              <li className={`menu-title ${activeItem === '/reports/eore'
                ? 'active'
                : ''}`}
              >
                <EoreIcon />
                EORE
              </li>
            </Link>
          )}

        {user?.permissions?.view_global_map && (
          <Link to={URLS.GLOBAL_MAP}>
            <li className={`menu-title ${activeItem === '/global-map/'
              ? 'active'
              : ''}`}
            >
              <MapIcon />
              Global map
            </li>
          </Link>
        )}
        {user?.permissions?.view_drones && (
          <Link to={URLS.DRONE_DATA}>
            <li className={`menu-title ${activeItem === '/drone-data/'
              ? 'active'
              : ''}`}
            >
              <DroneIcon />
              Drone data
            </li>
          </Link>
        )}
        {user?.permissions?.view_templates && (
          <Link to={URLS.EXPORT}>
            <li className={`menu-title ${activeItem === '/export/'
              ? 'active'
              : ''}`}
            >
              <ExportIcon />
              Export reports
            </li>
          </Link>
        )}

        {/* {user?.permissions?.view_machinery && (
          <Link to={URLS.MACHINERY_TRACKING}>
            <li className={`menu-title ${activeItem === '/machinery-tracking/'
              ? 'active'
              : ''}`}
            >
              <NetworkIcon />
              Machinery tracking
            </li>
          </Link>
        )} */}

        {user?.permissions?.add_nto_report && (
          <CreateReportDialog confirmPopup={confirmPopup} />
        )}
      </ul>

      <div className="nav-footer">
        <UserInfo />
        <Logout setLoader={props.setLoader} />
      </div>
    </nav>
  );
};

const Logout = () => {
  const navigate = useNavigate();
  const { logoutUser } = useAuth();

  const handleLogout = () => {
    logoutUser().then(() => {
      navigate('/login');
    });
  };

  return (
    <div className="logout">
      <img src={logoutIcon} alt="Logout icon" />
      <span onClick={handleLogout}>Log out</span>
    </div>
  );
};
