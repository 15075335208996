import { toast } from 'react-toastify';

export const checkFormMissingFields = (formData, setErrors) => {
  const sessionInformation = formData.info.session_information;
  if (!sessionInformation.settlement_code) {
    toast.error('You need to select a location');
    return false;
  }

  if (!formData.draft) {
    if (sessionInformation.place_type_imsma.value === 'other'
      && !sessionInformation.place_type_imsma.place_type_other) {
      toast.error('You need to specify the place type (IMSMA)');
      setErrors(['place_type_imsma_other']);
      return false;
    }

    if (!sessionInformation.eore_type_imsma) {
      toast.error('You need to specify the type of session (IMSMA)');
      return false;
    }

    if (!sessionInformation.eore_type_internal) {
      toast.error('You need to specify the type of session (Internal)');
      return false;
    }

    if (sessionInformation.eore_type_internal === 'other'
      && !sessionInformation.eore_type_internal_other) {
      toast.error('You need to specify the type of session (Internal)');
      setErrors(['eore_type_internal_other']);
      return false;
    }

    if (!sessionInformation.place_type_internal.value) {
      toast.error('You need to specify the place type (Internal)');
      return false;
    }

    if (!sessionInformation.place_type_imsma.value) {
      toast.error('You need to specify the place type (IMSMA)');
      setErrors(['place_type_imsma']);
      return false;
    }

    if (sessionInformation.place_type_internal.value === 'other'
      && !sessionInformation.place_type_internal.place_type_other) {
      toast.error('You need to specify the place type (Internal)');
      setErrors(['place_type_internal_other']);
      return false;
    }

    if (!sessionInformation.place_type_internal.value) {
      toast.error('You need to specify the place type (Internal)');
      setErrors(['place_type_internal']);
      return false;
    }

    if (sessionInformation.place_type_internal.value === 'state_institution'
      && !sessionInformation.place_type_internal.state_institution) {
      toast.error('You need to specify the state institution');
      setErrors(['state_institution']);
      return false;
    }

    if (sessionInformation.place_type_internal.value === 'education_facility'
      && !sessionInformation.place_type_internal.education_facility) {
      toast.error('You need to specify the education facility');
      setErrors(['education_facility']);
      return false;
    }

    if (sessionInformation.place_type_internal.value === 'critical_infrastructure'
      && !sessionInformation.place_type_internal.critical_infrastructure) {
      toast.error('You need to specify the critical infrastructure');
      setErrors(['critical_infrastructure']);
      return false;
    }

    if (typeof sessionInformation.number_of_session !== 'number') {
      toast.error('You need the number of sessions is required!');
      setErrors(['number_of_session']);
      return false;
    }

  }
  return true;
};
